<template>
    <div>
        <p class="text text-margin" v-for="text in texts" :key="text">
            {{ $t(text) }}
        </p>
        <p class="text"> {{ $t('faq.firestick.step2.list.title') }}</p>
        <ul v-for="text in listTexts" :key="text">
            <li class="text">{{ $t(text) }}</li>
        </ul>
        <div class="screenshot">
            <img class="step-image" src="./assets/step2.png" :alt="$t('faq.firestick.step2.selectMyFire')">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            texts: ['faq.firestick.step2.selectMyFire', 'faq.firestick.step2.ifYouAreUsing'],
            listTexts: ['faq.firestick.step2.list.items.olderFire', 'faq.firestick.step2.list.items.newestFire']
        }
    }
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
